import React from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { LinksFunction, MetaFunction } from '@remix-run/node';
import {
  Link,
  Links,
  Meta,
  Scripts,
  ScrollRestoration,
  useRouteError
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { AnimatePresence, motion, MotionConfig } from 'motion/react';

import pattern from '~/assets/images/pattern.png';
import Button from '~/components/Button';
import CookieConsent from '~/components/CookieConsent';
import Logo from '~/components/Logo';
import { ToastProvider } from '~/components/Toast';
import { AuthProvider } from '~/contexts/AuthContext';
import { ImageFormatProvider } from '~/contexts/ImageFormatContext';
import { WebPushProvider } from '~/contexts/WebPushContext';
import { generateSplashScreens } from '~/utils/generateSplashScreens';

import '~/styles/base.css';

export const meta: MetaFunction = () => [
  { title: 'Respawwwn - Daily Gaming Challenge Platform' },
  {
    name: 'description',
    content:
      'Challenge your gaming knowledge daily with Respawwwn! Identify video games from 360° panoramas, screenshots, and iconic sounds. Compete globally, earn points, and prove your gaming expertise. How well can you recognize famous and popular video games ? Can you defeat our Video Game quiz?'
  },
  { charSet: 'utf-8' },
  {
    name: 'viewport',
    content:
      'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover'
  },
  { name: 'theme-color', content: '#362977' },
  { name: 'mobile-web-app-capable', content: 'yes' },
  { name: 'apple-mobile-web-app-capable', content: 'yes' },
  {
    name: 'apple-mobile-web-app-status-bar-style',
    content: 'black-translucent'
  },
  { name: 'apple-touch-fullscreen', content: 'yes' },
  { name: 'apple-mobile-web-app-title', content: 'Respawwwn' },
  { property: 'og:site_name', content: 'Respawwwn' },
  { property: 'og:type', content: 'website' },
  { property: 'og:locale', content: 'en_US' },
  { property: 'og:image', content: '/og-image.jpg' },
  { property: 'og:image:width', content: '1200' },
  { property: 'og:image:height', content: '630' }
];

export const links: LinksFunction = () => [
  {
    rel: 'icon',
    type: 'image/png',
    href: '/favicon.png'
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: '/favicon-96x96.png',
    sizes: '96x96'
  },
  {
    rel: 'icon',
    type: 'image/svg+xml',
    href: '/favicon.svg'
  },
  {
    rel: 'shortcut icon',
    href: '/favicon.ico'
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/apple-touch-icon.png'
  },
  {
    rel: 'manifest',
    href: '/site.webmanifest'
  },
  {
    rel: 'preconnect',
    href: 'https://fonts.googleapis.com'
  },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Teko:wght@300..700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Nanum+Pen+Script&family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap'
  },
  {
    type: 'text/plain',
    rel: 'author',
    href: '/humans.txt'
  },
  {
    type: 'application/xml',
    rel: 'sitemap',
    href: '/sitemap.xml'
  },
  ...generateSplashScreens()
];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html
      lang="en"
      className="w-full touch-manipulation select-none bg-purple-800"
    >
      <head>
        <Meta />
        <Links />

        {/* Google tag (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-64TP2WXPH7"
        />
        <script
          /* eslint-disable react/no-danger */
          dangerouslySetInnerHTML={{
            __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-64TP2WXPH7');
        `
          }}
        />
      </head>
      <body className="relative flex touch-pan-x touch-pan-y text-white selection:bg-purple-500">
        <ToastProvider>
          <MotionConfig reducedMotion="user">
            {/* LOGO */}
            <Logo centered />

            {children}
            <ScrollRestoration />
            <Scripts />
          </MotionConfig>
        </ToastProvider>

        {/* BACKGROUND */}
        <div
          className="absolute -left-7 -top-7 bottom-0 right-0 -z-10"
          style={{ backgroundImage: `url(${pattern})` }}
        >
          <div className="circular-gradient absolute inset-0 rounded-t-2xl" />
        </div>
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return (
    <AnimatePresence mode="wait" initial>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="h-viewport flex w-full flex-col items-center justify-center gap-6 p-4 text-center"
      >
        <div className="flex flex-col items-center gap-4">
          <h1 className="h-3xl md:h-4xl">Oops! Something went wrong</h1>
          <p className="h-base max-w-md text-white/80">
            We encountered an error while loading the page. Please try again
            later.
          </p>
        </div>

        <Link to="/" className="mt-4">
          <Button variant="green" size="large">
            Back to Home
          </Button>
        </Link>
      </motion.div>
    </AnimatePresence>
  );
};

export default function App() {
  const outlet = useOutlet();
  const location = useLocation();
  const isRedirect = location.state?.isRedirect;

  return (
    <AuthProvider>
      <WebPushProvider>
        <ImageFormatProvider>
          <AnimatePresence mode="wait" initial>
            <motion.main
              key={location.pathname}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.1 }}
              exit={isRedirect ? undefined : { opacity: 0 }}
              className="relative grow overflow-hidden"
            >
              {outlet}
            </motion.main>
          </AnimatePresence>

          {/* COOKIE BANNER */}
          <CookieConsent />
        </ImageFormatProvider>
      </WebPushProvider>
    </AuthProvider>
  );
}
