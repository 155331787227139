import React, { useEffect, useState } from 'react';
import { Link } from '@remix-run/react';
import { AnimatePresence, motion } from 'motion/react';

import Button from '~/components/Button';

export default function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);

  const handleAcknowledge = () => {
    document.cookie =
      'cookie-consent=acknowledged; max-age=31536000; path=/; SameSite=Strict';
    setIsVisible(false);
  };

  useEffect(() => {
    const hasConsent = document.cookie
      .split('; ')
      .find(row => row.startsWith('cookie-consent='));
    if (!hasConsent) {
      setIsVisible(true);
    }
  }, []);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="fixed left-3 z-30 rounded-2xl bg-gray-500 p-6 px-5 pb-4 shadow-hint backdrop-blur-2xl bottom-safe-offset-3 max-md:right-3 md:left-6 md:max-w-md md:bottom-safe-offset-6"
          data-cy="cookie-consent--banner"
          initial={{ x: 0 }}
          animate={{ x: 0 }}
          exit={{ x: '-110%' }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
        >
          <div className="h-base mb-10 space-y-3 *:[filter:none] *:[text-shadow:none]">
            <p>
              We use essential cookies to power up your gaming experience and
              secure sign-in.
            </p>
            <p className="">
              Please read through our{' '}
              <Link
                to="/terms-and-conditions"
                className="underline underline-offset-4"
                data-cy="cookie-consent--terms-of-use"
              >
                Terms of use
              </Link>
              {' and '}
              <Link
                to="/privacy-policy"
                className="underline underline-offset-4"
                data-cy="cookie-consent--privacy-policy"
              >
                Privacy policy
              </Link>
              .
            </p>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={handleAcknowledge}
              variant="purple"
              size="small"
              data-cy="cookie-consent--acknowledge"
            >
              Got it, let's Play!
            </Button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
